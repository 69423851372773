<template>
	<div class="w100 bg-fff wtm_height2 pl-24 pr-24 pt-30">
		<div class="w100 dp-f fw-w mb-24 jc-sb">
			<div class="dp-f">
				<oabutton class="mr-20" width='120' height='40' title="新增个人" CSStype=2 style="font-size:16px;"
					v-permission="'operation_addressbook:add'" @buttonclick="clickUserCheck(()=>{unref(operationRef).add()})"></oabutton>
				<oabutton width='120' height='40' title="批量导入" CSStype=2 style="font-size:16px;"
					v-permission="'operation_addressbook:add'" @buttonclick="clickUserCheck(()=>{unref(batchRef).add()})"></oabutton>
			</div>
			<div class="dp-f ai-c">
				<div class="input_box w-200" style="margin-right:10px;">
					<div class="input_title">认证状态：</div>
					<el-select v-model="state.searchKey.isCertification" class="input" size="large">
						<el-option v-for="item in state.options" :key="item.value" :label="item.label" :value="item.value" />
					</el-select>
				</div>
				<div class="input_box w-230">
					<el-input class="input search_input" v-model="state.searchKey.nameOrPhone" :maxlength="config.inputMaxlength"
						show-word-limit placeholder="联系人/联系方式">
						<template #append>
							<img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="getdatalb(1)">
						</template>
					</el-input>
				</div>
				<oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="mr-20"
        @buttonclick="getdatalb(1)"></oabutton>
				<img class="w-22 h-22 cu-p mr-20" src="@/assets/img/general/derive.png" @click="exportExcel()">
				<img class="w-22 h-22 cu-p" src="@/assets/img/general/refresh.png" @click="getdatalb(currentPage)">
			</div>
		</div>
		<el-table :data="state.Notice" class="mb-20" :header-cell-style="{
			background: 'var(--active-alpha)',
			color: config.table.color,
			fontWeight: config.table.fontWeight,
			fontSize: config.table.fontSize,
		}" row-key="id" @selection-change="handleSelectionChange" v-loading="loading"
			:element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner">
			<el-table-column type="selection" width="55" />
			<el-table-column :align="'center'" prop="name" label="姓名" />
			<el-table-column :align="'center'" prop="code" label="联系方式" />
			<el-table-column :align="'center'" label="认证状态">
				<template #default="scope">
					<div class="statusbut dp-fc" :class="'statusbut' + scope.row.isCertification">
						<img class="mr-4" v-if="scope.row.isCertification==1" src="@/assets/img/addressbook/certification1.png">
						<img class="mr-4" v-else src="@/assets/img/addressbook/certification0.png">
						{{ getisCertification(scope.row.isCertification) }}
					</div>
				</template>
			</el-table-column>
			<el-table-column :align="'center'" prop="createDate" label="创建时间" />
			<el-table-column :align="'center'" label="操作">
				<template #default="scope">
					<div class="dp-fc fw-w spanbutbox">
						<div class="Editor_span" v-permission="'operation_addressbook:edit'"
							@click="clickUserCheck(()=>{unref(operationRef).editclick(scope.row)})">
							编辑
						</div>
						<div class="Delete_span" v-permission="'operation_addressbook:del'" @click="clickUserCheck(()=>{deteleClick(scope.row)})">
							删除
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
			layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
		</el-pagination>
	</div>
	<!-- 单用户操作弹出框 -->
	<operation ref="operationRef" @Emit="getdatalb(currentPage)"></operation>
	<!-- 批量导入弹出框 -->
	<batch ref="batchRef" @Emit="batchData"></batch>
	<!-- 批量导入确认弹出框  -->
	<confirm ref="confirmRef" @Emit="getdatalb(currentPage)"></confirm>
</template>
<script setup>
import { reactive, ref, unref, onMounted } from "vue";
import { getisCertification } from "@/utils/server/getcode.js"
import { getisCertificationData } from "@/utils/server/selectdata.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import { export2Excel } from "@/utils/server/file";
import { queryResourceTable } from "@/api/cancelToken.js"
import { httpToken } from "@/utils/request";
import {clickUserCheck} from '@/utils/base/realname.js'
import qs from "qs";
import operation from "./individual/operation.vue"
import batch from "./individual/batch.vue"
import confirm from "./individual/confirm.vue"
const loading = ref(false); //loading 显示
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const operationRef = ref();//单用户操作弹出框ref
const batchRef = ref();//批量导入弹出框ref
const confirmRef = ref();//批量导入确认弹出框ref
const state = reactive({
	searchKey: {
		isCertification: "", //认证状态
		nameOrPhone: "",//联系人或联系方式
	}, //搜索绑定值
	options: getisCertificationData(),//下拉数据
	Notice: [], //表格数据
	columns: [
		{ title: "姓名", key: "name" },
		{ title: "联系方式", key: "code" },
		{ title: "认证状态", key: "isCertification", reset: { 0: '未认证', 1: '认证' } },
		{ title: "创建时间", key: "createDate" },
	],
	multipleSelection: [],//列表多选绑定值
});
// 多选绑定值改变
const handleSelectionChange = (val) => {
	state.multipleSelection = val
}
//导出点击
const exportExcel = (() => {
	if (state.multipleSelection.length == 0) {
		handleMessage('请先选择列表数据')
		return
	}
	export2Excel(state.columns, state.multipleSelection, "企业通讯录");
})
// 删除点击
const deteleClick = ((row) => {
	handleCofirm().then(() => {
		httpToken({
			method: "post",
			url: '/admin/addressbook/del',
			data: qs.stringify({
				ids: row.id
			})
		}).then(() => {
			getdatalb(currentPage.value)
		})
	}).catch(() => {
		handleMessage('已取消删除', 'info')
	})
})
// 分页改变方法
const handleCurrentChange = ((val) => {
	getdatalb(val)
})
// 列表调取 页数 传入构造器
const getdatalb = ((val) => {
	state.multipleSelection = []
	let json = [
		{ column: "create_date", type: 'orderByDesc' }
	];
	currentPage.value = val;
	loading.value = true
	queryResourceTable('/admin/addressbook/list', qs.stringify({
		page: val,
		paramData: JSON.stringify(json),
		isCertification: state.searchKey.isCertification,//认证状态
		search: state.searchKey.nameOrPhone,//联系人或联系方式
		type: 0,// 0：个人，1：公司
	})).then((res) => {
		// 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
		if (val !== res.data.current) {
			getdatalb(res.data.current)
			return
		}
		totalPage.value = res.data.total;
		state.Notice = res.data.records
		loading.value = false;
	}).catch((err)=>{
		if(err.message!='stop')
			loading.value = false;
	})
})
onMounted(() => {
	getdatalb(1, [])
})
// 批量导入获取数据
const batchData = ((data) => {
	console.log('批量导入获取数据', data)
	unref(confirmRef).getData(data)
})
</script>
<style lang="scss" scoped >
.statusbut {
	width: 102px;
	height: 44px;
	margin: 0 auto;
	background: #E8E8E8;
	border-radius: 4px;
	border: 1px solid #D4D4D4;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
}

.statusbut1 {
	background: rgba(82, 196, 26, 0.1);
	color: #52C41A;
	border-radius: 4px;
	border: none;
}
</style>